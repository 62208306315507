import React, { useEffect, useState } from 'react';
import ModalCausesSymptoms from "./ModalCausesSymptoms";
import firebase from "../../firebaseConfig";
import "../../styles/generalTabs.css"
import {TiDelete, TiPlus} from "react-icons/ti";

const CausesSymptoms = ({ user }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [displayContent, setDisplayContent] = useState({});
    const [fieldName, setFieldName] = useState("");
    const [editIndex, setEditIndex] = useState("");
    const [causesSymptomsData, setcausesSymptomsData] = useState([{},{},{},{},{}]);

    useEffect(() => {
        const fetchInputValue = async () => {
            try {
                if (user) {
                    const userId = firebase.auth().currentUser.uid;
                    const docRef = firebase.firestore().collection('users').doc(userId);
                    const docSnapshot = await docRef.get();
                    if (docSnapshot.exists) {
                        const data = docSnapshot.data().CausesSymptoms_10;
                        setcausesSymptomsData(data);
                    }
                }
            } catch (error) {
                console.log(error);
                // Gérer les erreurs de récupération des données
            }
        };
        fetchInputValue();
    }, [user]);

    const updateInputValue = async ({ newCauseValue, newSymptomValue, newPreventionValue }) => {
        try {
            if (user) {
                const userId = user.uid;
                const docRef = firebase.firestore().collection('users').doc(userId);

                // Mettre à jour le tableau des causes et symptômes en copiant l'état actuel
                const updatedData = [...causesSymptomsData];

                if (editIndex === -1) {
                    // Index -1 indique la création d'un nouvel élément
                    updatedData.push({ cause: newCauseValue, symptom: newSymptomValue, prevention: newPreventionValue });
                } else {
                    // Mettre à jour l'objet à l'index spécifié avec les nouvelles valeurs
                    updatedData[editIndex] = { cause: newCauseValue, symptom: newSymptomValue, prevention: newPreventionValue };
                }

                // Mettre à jour l'état avec le tableau mis à jour
                setcausesSymptomsData(updatedData);

                // Mettre à jour les données dans Firestore
                await docRef.update({ CausesSymptoms_10: updatedData });

                // Fermer la modal
                if (modalIsOpen) setModalIsOpen(false);
            }
        } catch (error) {
            console.log(error);
            // Gérer les erreurs de mise à jour des données
        }
    };

    const handleDelete = async (index) => {
        try {
            if (user) {
                const userId = user.uid;
                const docRef = firebase.firestore().collection('users').doc(userId);

                // Copiez le tableau actuel
                const updatedData = [...causesSymptomsData];

                // Supprimez l'élément à l'index spécifié
                updatedData.splice(index, 1);

                // Mettre à jour l'état avec le tableau mis à jour
                setcausesSymptomsData(updatedData);

                // Mettre à jour les données dans Firestore
                await docRef.update({ CausesSymptoms_10: updatedData });
            }
        } catch (error) {
            console.log(error);
            // Gérer les erreurs de suppression des données
        }
    };

    const openModal = (index, title) => {
        if (index === -1) {
            // Index -1 indique la création d'un nouvel élément
            setDisplayContent({ cause: "", symptom: "", prevention: "" });
            setEditIndex(index);
        } else {
            setDisplayContent(causesSymptomsData[index]);
            setEditIndex(index);
        }
        setFieldName(fieldName);
        setModalTitle(title);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <div className="tab-pane containerDashboardContent container-fluid" role="tabpanel" id="tab-10">
            <h5 className={"mt-3"}>
                <span className="introText">
                     "If your working circumstances are costing you your physical, mental, and emotional health, it's too expensive." - Robin Kirby
                </span>
            </h5>
            <div>
                <div className="table-responsive d-flex flex-column align-items-center">
                    <table className="table w-75">
                        <thead>
                        <tr>
                            <th>
                                <span style={{ textDecoration: "underline", fontSize: "1.5em" }}>CAUSES</span><br/>
                                <span className={"subtitleMaj"}>CREATE A LIST OF ELEMENTS LEADING TO OVERREACH AND OVERLOAD BURNOUT</span>
                            </th>
                            <th>
                                <span style={{ textDecoration: "underline", fontSize: "1.5em" }}>SYMPTOMS</span><br/>
                                <span className={"subtitleMaj"}>CREATE A LIST OF OVERREACH AND OVERLOAD BURNOUT SYMPTOMS</span>
                            </th>
                            <th>
                                <span style={{ textDecoration: "underline", fontSize: "1.5em" }}>PREVENTIONS & SOLUTIONS</span><br/>
                                <span className={"subtitleMaj"}>CREATE A LIST OF PREVENTIVE SOLUTIONS AGAINST OVERREACHING AND OVERLOAD BURNOUT</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {causesSymptomsData?.map((item, index) => (
                            <tr key={index} onDoubleClick={() => openModal(index, "Edit")}>
                                <td>
                                    <div className="contentText">{item.cause}</div>
                                </td>
                                <td>
                                    <div className="contentText">{item.symptom}</div>
                                </td>
                                <td>
                                    <div className="contentText">{item.prevention}</div>
                                </td>
                                <TiDelete style={{ fontSize: "2.3em", cursor:"pointer" }} className={"text-danger mt-4"} onClick={() => handleDelete(index)}/>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div className="w-100 d-flex justify-content-center">
                        <TiPlus style={{ fontSize: "1.8em", cursor:"pointer" }} className={"text-primary"} onClick={() => openModal(-1, "Create")}/>
                    </div>
                </div>
            </div>

            {modalIsOpen && (
                <ModalCausesSymptoms
                    modalTitle={modalTitle}
                    closeModal={closeModal}
                    causeValue={displayContent.cause}
                    symptomValue={displayContent.symptom}
                    preventionValue={displayContent.prevention}
                    isOpen={modalIsOpen}
                    saveFields={updateInputValue}
                />
            )}
        </div>
    );
};

export default CausesSymptoms;
